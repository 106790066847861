import styles from "./card.module.css";
import likei from "../../images/like.png";
import likeactive from "../../images/likeactive.png";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Tdata } from "../../utils/data";

type Tprops = {
  ked: Tdata;
};

const Card = (props: Tprops) => {
  const item = props.ked;

  const [likeu, setLikeu] = useState(false);

  const changeLike = () => {
    setLikeu(!likeu);
  };

  return (
    <div className={styles.main}>
      <img className={styles.image} src={item.image} alt={item.name} />
      <div className={styles.text}>{item.name}</div>
      <div className={styles.text}>{item.description}</div>
      <div className={styles.text}>`${item.price}€`</div>
      <div className={styles.text}>{item.size}</div>

      <div className={styles.like}>
        <div className={styles.likebox} onClick={changeLike}>
          {likeu && (
            <img className={styles.likeimage} src={likeactive} alt={"like"} />
          )}
          {!likeu && (
            <img className={styles.likeimage} src={likei} alt={"like"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
//{ok && <div className={styles.textform}>{oktext}</div>}
