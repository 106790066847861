import FormReg from "../../components/regform/regform";
import styles from "./admin.module.css";

function AdminPage() {
  return (
    <div className={styles.main}>
      <header className={styles.header}>
        <h1 className={styles.text}>Admin panel</h1>
      </header>
    </div>
  );
}

export default AdminPage;
