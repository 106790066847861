import styles from "./cards.module.css";
import { list } from "../../typest/data";
import Card from "../card/card";
import { NavLink } from "react-router-dom";
import { data } from "../../utils/data";

function Cards() {
  const listcard = data;

  return (
    <div className={styles.main}>
      <div className={styles.list}>
        {listcard.map((item, index) => {
          const ite = { item: item };
          return (
            <NavLink
              className={styles.link}
              to={`/comanda/${item.id}`}
              key={index}
            >
              <Card ked={item}></Card>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}

export default Cards;
