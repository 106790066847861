import styles from "./buttonsmall.module.css";
import exit from "../../images/exit.png";

type TButton = {
  func: void;
};
function Buttonsmall(props: TButton) {
  return (
    <button className={styles.main} onClick={() => props.func}>
      <img className={styles.icon} src={exit} alt="exit" />
    </button>
  );
}

export default Buttonsmall;
