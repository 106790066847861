import React, { useEffect } from "react";
import { useAppDispatch } from "../../utils/hook";
import styles from "./app.module.css";
import { Route, Routes } from "react-router-dom";
import MainPage from "../../pages/main/main";
import OnePage from "../../pages/onepage/onepage";
import TwoPage from "../../pages/twopage/twopage";
import AdminPage from "../../pages/admin/admin";
import Header from "../header/header";
import Footer from "../footer/footer";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    //dispatch(checkUserAuth())
  }, []);
  const isAuth = false;
  return (
    <div className={styles.app}>
      <Header></Header>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/comanda" element={<OnePage />} />
        <Route path="/admin" element={<AdminPage />} />

        <Route path="/comanda/:id" element={<TwoPage />} />
        <Route path="/*" element={<MainPage />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
