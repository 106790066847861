import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getproducts } from "../utils/api";
import { Tproduct } from "../typest/types";

export type Tproducts = {
  products: Tproduct[];
  isLoad: boolean;
};

export const initialState: Tproducts = {
  products: [],
  isLoad: false,
};

export const fetchList = createAsyncThunk<
  Tproduct[],
  undefined,
  { rejectValue: string }
>("prodacts/get", async () => {
  const res = await getproducts();
  const data = res.data;
  return data;
});

export const products = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchList.pending, (state) => {
        state.isLoad = false;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        console.log(action.payload);
        state.products = action.payload;
        state.isLoad = true;
      })
      .addCase(fetchList.rejected, (state) => {
        state.isLoad = false;
      });
  },
});

export default products.reducer;
