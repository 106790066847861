import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { gettypes } from "../utils/api";

export type Ttype = {
  id: string;
  name: string;
  img: string;
};

export type Ttypes = {
  types: Ttype[];
  isLoad: boolean;
};

export const initialState: Ttypes = {
  types: [],
  isLoad: false,
};

export const fetchList = createAsyncThunk<
  Ttype[],
  undefined,
  { rejectValue: string }
>("types/get", async () => {
  const res = await gettypes();
  const data = res.data;
  return data;
});

export const types = createSlice({
  name: "types",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchList.pending, (state) => {
        state.isLoad = false;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        console.log(action.payload);
        state.types = action.payload;
        state.isLoad = true;
      })
      .addCase(fetchList.rejected, (state) => {
        state.isLoad = false;
      });
  },
});

export default types.reducer;
