import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TUser, TUserone } from "../typest";
import { Tproduct } from "../typest/types";

export type TOrder = {
  prodact: Tproduct;
  quantity: number;
};

export type Tbasket = {
  order: TOrder[];
  dataactive: string;
  comment: string;
};

export const initialState: Tbasket = {
  order: [],
  dataactive: "",
  comment: "",
};

export const basket = createSlice({
  name: "basket",
  initialState: initialState,
  reducers: {
    setbasket: (state, action: PayloadAction<Tbasket>) => {
      state.order = action.payload.order;
      state.dataactive = action.payload.dataactive;
      state.comment = action.payload.comment;
    },
    addOrder: (state, action: PayloadAction<TOrder>) => {
      const ingred = action.payload;

      state.order.push(ingred);
    },
    delOrder: (state, action: PayloadAction<number>) => {
      const ingred = action.payload;

      state.order.splice(action.payload, 1);
    },
  },
});

export default basket.reducer;
export const { setbasket, addOrder, delOrder } = basket.actions;
