import { NavLink } from "react-router-dom";
import FormReg from "../regform/regform";
import styles from "./banner.module.css";
import imageone from "../../images/rr.png";
import imagetwo from "../../images/rr2.png";
import { useEffect, useState } from "react";

type Tprops = {
  image: string[];
  textone: string;
  texttwo: string;
};
type Tdefaults = {
  index: number;
  step: number;
  active: boolean;
};
const defaults: Tdefaults = {
  index: 0,
  step: 1,
  active: true,
};

function Banner(props: Tprops) {
  const [image, setimage] = useState(props.image[0]);
  const [active, setactive] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isVisibleuser, setIsVisibleuser] = useState(false);
  const [state, setState] = useState<Tdefaults>({
    index: 0,
    step: 1,
    active: true,
  });
  const now = new Date();
  let i = 0;

  useEffect(() => {
    let delaym = 10000;
    if (isVisible) {
      const timeoutId = setTimeout(setState, delaym, ({ ...state }) => {
        if (state.active) {
          state.index += state.step;
          state.active = false;
          if (state.index === props.image.length - 1) {
            state.step = -1;
          } else if (state.index === 0) {
            state.step = 1;
          }
        } else {
          state.active = true;
        }
        setimage(props.image[state.index]);
        return state;
      });

      return () => clearTimeout(timeoutId);
    }
  });

  return (
    <div
      className={styles.main}
      style={{ backgroundImage: `url(${image})` }}
      key={state.index}
    >
      <div className={styles.banner} onClick={() => setactive(active + 1)}>
        <h2 className={`${styles.title} ${styles.titleA}`}>{props.textone}</h2>
        <h6 className={`${styles.title} ${styles.titleB}`}>{props.texttwo}</h6>
        <NavLink className={styles.link} to="/promo">
          Shop
        </NavLink>
      </div>
    </div>
  );
}

export default Banner;
