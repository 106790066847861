import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Tpartners = {
  email: string;
  name: string;
  phone: number;
  avatar: string;
  rol: string;
};

export const initialState: Tpartners = {
  email: "",
  name: "",
  phone: 0,
  avatar: "",
  rol: "",
};

export const partnersSlice = createSlice({
  name: "partners",
  initialState: initialState,
  reducers: {
    setpart: (state, action: PayloadAction<Tpartners>) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.phone = action.payload.phone;
      state.avatar = action.payload.avatar;
      state.rol = action.payload.rol;
    },
  },
});

export default partnersSlice.reducer;
export const { setpart } = partnersSlice.actions;
