import { useParams } from "react-router-dom";
import styles from "./twopage.module.css";
import { list } from "../../typest/data";
import Card from "../../components/card/card";
import Person from "../../components/person/person";

function TwoPage() {
  const param = Number(useParams().id);

  const result = list.filter((one) => one.id === param);

  return (
    <div className={styles.main}>
      <Person
        email={result[0].email}
        phone={result[0].phone}
        name={result[0].name}
        rol={result[0].rol}
        description={result[0].description}
        avatar={result[0].avatar}
      ></Person>
    </div>
  );
}

export default TwoPage;
