import { configureStore, combineReducers } from "@reduxjs/toolkit";

import screenReducer from "./screen";

import userReducer from "./user";

import partnersReducer from "./partners";
import personsReducer from "./persons";
import productsReducer from "./prodact";
import brendsReducer from "./brends";
import typesReducer from "./types";
import basketReducer from "./basket";

const rootReducer = combineReducers({
  screen: screenReducer,
  user: userReducer,
  partners: partnersReducer,
  persons: personsReducer,
  products: productsReducer,
  brends: brendsReducer,
  types: typesReducer,
  basket: basketReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispath = typeof store.dispatch;
