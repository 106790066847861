import { sait } from "./data";

const config = {
  headers: {
    authorization: "5b991593-2d40-406d-bb45-a04e21468b74",
    "Content-Type": "application/json",
  },
};

export function checkResponse(res: Response) {
  if (res.ok) {
    return res.json();
  }
  return Promise.reject(`ошибка ${res.status}`);
}

export const getpersons = () => {
  return fetch(sait).then(checkResponse);
};

export const getbrends = () => {
  return fetch(sait).then(checkResponse);
};

export const getproducts = () => {
  return fetch(sait).then(checkResponse);
};

export const gettypes = () => {
  return fetch(sait).then(checkResponse);
};
