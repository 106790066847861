import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getpersons } from "../utils/api";

export type Tperson = {
  email: string;
  id: string;
  name: string;
  avatar: string;
  description: string;
  rol: string;
  like: boolean;
};

export type Tpersons = {
  persons: Tperson[];
  isLoad: boolean;
};

export const initialState: Tpersons = {
  persons: [],
  isLoad: false,
};

export const fetchList = createAsyncThunk<
  Tperson[],
  undefined,
  { rejectValue: string }
>("persons/get", async () => {
  const res = await getpersons();
  const data = res.data;
  return data;
});

export const persons = createSlice({
  name: "persons",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchList.pending, (state) => {
        state.isLoad = false;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        console.log(action.payload);
        state.persons = action.payload;
        state.isLoad = true;
      })
      .addCase(fetchList.rejected, (state) => {
        state.isLoad = false;
      });
  },
});

export default persons.reducer;
