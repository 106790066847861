import { useResize } from "../../utils/hook";
import Button from "../button/button";
import Buttonsmall from "../buttonsmall/buttonsmall";
import styles from "./oneheader.module.css";

export function OneHeader() {
  const wl = useResize().isScreenMd;
  const onClick = () => {};
  return (
    <div className={styles.main}>
      <div className={styles.left}></div>
      <div className={styles.titleblock}>
        <h1 className={styles.title}>Наша команда</h1>
        <h2 className={styles.text}>
          Это опытные специалисты, хорошо разбирающиеся во всех задачах, которые
          ложатся на их плечи, и умеющие находить выход из любых, даже самых
          сложных ситуаций.
        </h2>
      </div>

      {wl && (
        <div className={styles.right}>
          <Button name={"Выход"} func={onClick()}></Button>
        </div>
      )}
      {!wl && (
        <div className={styles.right}>
          <Buttonsmall func={onClick()}></Buttonsmall>
        </div>
      )}
    </div>
  );
}
export default OneHeader;
