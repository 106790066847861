export const LOGIN_ROUT = "/";
export const PERSON_ROUT = "/person/:id";
export const COMAND_ROUT = "/person";

export const sait = "/moisait";
export const saitlogin = "/moisait";
export const saituser = "/moisait";
export const saittoken = "/moisait";
export const saitregister = "/moisait";
export const saitlogout = "/moisait";
export const saitfogot = "/moisait";
export const saitreset = "/moisait";

export const listMenu = [
  { name: "Brands", link: "/admin/brands" },
  { name: "Types", link: "/admin/types" },
  { name: "Prodact", link: "admin/prodact" },
  { name: "Partners", link: "admin/partners" },
  { name: "Users", link: "admin/users" },
  { name: "Posts", link: "admin/posts" },
  { name: "Market", link: "admin/market" },
  { name: "Orders", link: "admin/orders" },
];

export const description =
  "All works are done by hand. Each work is individual and not repeatable";

export type Tdata = {
  name: string;
  id: number;
  size: string;
  color: string;
  type: string;
  description: string;
  price: number;
  quantity: number;
  datap: string;
  image: string;
  brand: string;
  imagelist: string[];
};

export type Tenis = {
  id: number;
  color: string;
  type: string;
  description: string;
  price: string;
  image: string;
  brand: string;
  imagelist: string[];
};

export const tenis = [
  {
    id: 5029072024001,
    color: "blue",
    type: "High Tops",
    brand: "Primark",

    price: 20,
    image: "../../images/blue01",
    imagelist: ["../../images/blue01", "../../images/blue02"],
  },
  {
    id: 5029072024003,
    size: "42",
    color: "red",
    type: "Slip-ons",
    brand: "Convers",

    price: 60,
    image: "../../images/red01",
    imagelist: ["../../images/red01", "../../images/red02"],
  },
  {
    id: 5029072024003,
    size: "44",
    color: "black",
    type: "Slip-ons",
    brand: "Convers",

    price: 60,
    image: "../../images/red01",
    imagelist: ["../../images/red01", "../../images/red02"],
  },
  {
    id: 5029072024004,
    size: "43",
    color: "pink",
    type: "Slip-ons",
    brand: "Convers",

    price: 60,
    image: "../../images/roze01l",
    imagelist: ["../../images/roze01l", "../../images/roze02l"],
  },
  {
    id: 5029072024005,
    size: "43",
    color: "white",
    type: "Slip-ons",
    brand: "Convers",

    price: 60,
    image: "../../images/white01l",
    imagelist: ["../../images/white01l", "../../images/white02l"],
  },

  {
    id: 5029072024006,
    size: "39",
    color: "pink",
    type: "High Tops",
    brand: "Convers",

    price: 80,
    image: "../../images/roze01",
    imagelist: ["../../images/roze01", "../../images/roze02"],
  },
  {
    id: 5029072024007,
    size: "39",
    color: "lite green",
    type: "High Tops",
    brand: "Convers",

    price: 80,
    image: "../../images/roze01",
    imagelist: ["../../images/roze01", "../../images/roze02"],
  },
  {
    id: 5029072024008,
    size: "39",
    color: "lite green",
    type: "High Tops",
    brand: "Convers",

    price: 80,
    image: "../../images/litegreen01",
    imagelist: ["../../images/litegreen01", "../../images/litegreen02"],
  },
  {
    id: 5029072024009,
    size: "39",
    color: "green",
    type: "High Tops",
    brand: "Convers",

    price: 80,
    image: "../../images/green01",
    imagelist: ["../../images/green01", "../../images/green02"],
  },
  {
    id: 5029072024010,
    size: "39",
    color: "broun",
    type: "High Tops",
    brand: "Convers",

    price: 80,
    image: "../../images/broun01",
    imagelist: ["../../images/broun01", "../../images/broun02"],
  },
  {
    name: "Rainbow Unicorn",
    id: 5029072024011,
    size: "38",
    color: "liteblue",
    type: "High Tops",
    brand: "Convers",

    price: 80,
    image: "../../images/bluelite01",
    imagelist: ["../../images/bluelite01", "../../images/bluelite02"],
  },
  {
    id: 5029072024012,
    size: "40",
    color: "white",
    type: "High Tops",
    brand: "Convers",

    price: 80,
    image: "../../images/white01",
    imagelist: ["../../images/white01", "../../images/white02"],
  },
];

export const data = [
  {
    name: "Toby & flowers",
    id: 29072024001,
    size: "42",
    color: "blue",
    type: "High Tops",
    brand: "Primark",
    description:
      "Sneakers with a portrait of your pet, what could be cuter and more touching",
    price: 120,
    quantity: 1,
    datap: "01/01/2024",
    image: "../images/blue01",
    imagelist: ["../images/blue01", "../images/blue02"],
  },
  {
    name: "Spaider-man",
    id: 29072024002,
    size: "42",
    color: "red",
    type: "Slip-ons",
    brand: "Convers",
    description:
      "Sneakers with the friendliest neighbor and you're ready for adventure",
    price: 100,
    quantity: 1,
    datap: "01/01/2024",
    image: "../../images/red01",
    imagelist: ["../../images/red01", "../../images/red02"],
  },
  {
    name: "V for Vendetta",
    id: 29072024003,
    size: "44",
    color: "black",
    type: "Slip-ons",
    brand: "Convers",
    description: "V for Vendetta - Style or Life",
    price: 100,
    quantity: 1,
    datap: "01/01/2024",
    image: "../../images/red01",
    imagelist: ["../../images/red01", "../../images/red02"],
  },
  {
    name: "Pride",
    id: 29072024004,
    size: "43",
    color: "roze",
    type: "Slip-ons",
    brand: "Convers",
    description: "Pride Month and Day: love conquers all",
    price: 100,
    quantity: 1,
    datap: "01/01/2024",
    image: "../../images/roze01l",
    imagelist: ["../../images/roze01l", "../../images/roze02l"],
  },
  {
    name: "Surfing",
    id: 29072024005,
    size: "43",
    color: "white",
    type: "Slip-ons",
    brand: "Convers",
    description: "Surfing: Where The Ocean Meets The Soul",
    price: 100,
    quantity: 1,
    datap: "01/01/2024",
    image: "../../images/white01l",
    imagelist: ["../../images/white01l", "../../images/white02l"],
  },

  {
    name: "Rose",
    id: 29072024006,
    size: "39",
    color: "pink",
    type: "High Tops",
    brand: "Convers",
    description: "Truths and roses have thorns about them",
    price: 110,
    quantity: 1,
    datap: "01/01/2024",
    image: "../../images/roze01",
    imagelist: ["../../images/roze01", "../../images/roze02"],
  },
  {
    name: "Rose",
    id: 29072024007,
    size: "39",
    color: "lite green",
    type: "High Tops",
    brand: "Convers",
    description: "Truths and roses have thorns about them",
    price: 110,
    quantity: 1,
    datap: "01/01/2024",
    image: "../../images/roze01",
    imagelist: ["../../images/roze01", "../../images/roze02"],
  },
  {
    name: "Spring",
    id: 29072024008,
    size: "39",
    color: "lite green",
    type: "High Tops",
    brand: "Convers",
    description: "No matter how long the winter, spring is sure to follow.",
    price: 110,
    quantity: 1,
    datap: "01/01/2024",
    image: "../../images/litegreen01",
    imagelist: ["../../images/litegreen01", "../../images/litegreen02"],
  },
  {
    name: "Time of magic",
    id: 29072024009,
    size: "39",
    color: "green",
    type: "High Tops",
    brand: "Convers",
    description:
      "I Won't Blast People Out Of My Way Just Because They're There",
    price: 110,
    quantity: 1,
    datap: "01/01/2024",
    image: "../../images/green01",
    imagelist: ["../../images/green01", "../../images/green02"],
  },
  {
    name: "Fawn",
    id: 29072024010,
    size: "39",
    color: "broun",
    type: "High Tops",
    brand: "Convers",
    description: "I'll tell you, if you'll come a little further on",
    price: 110,
    quantity: 1,
    datap: "01/01/2024",
    image: "../../images/broun01",
    imagelist: ["../../images/broun01", "../../images/broun02"],
  },
  {
    name: "Rainbow Unicorn",
    id: 29072024011,
    size: "38",
    color: "liteblue",
    type: "High Tops",
    brand: "Convers",
    description: "Born to Ride Unicorns.",
    price: 110,
    quantity: 1,
    datap: "01/01/2024",
    image: "../../images/bluelite01",
    imagelist: ["../../images/bluelite01", "../../images/bluelite02"],
  },
  {
    name: "A little prince",
    id: 29072024012,
    size: "40",
    color: "white",
    type: "High Tops",
    brand: "Convers",
    description:
      "Where are the people?” resumed the little prince at last. “It’s a little lonely in the desert…” “It is lonely when you’re among people, too,” said the snake.",
    price: 110,
    quantity: 1,
    datap: "01/01/2024",
    image: "../../images/white01",
    imagelist: ["../../images/white01", "../../images/white02"],
  },
];
