import styles from "./footer.module.css";
import cart from "../../images/cartt.png";
import tel from "../../images/telegram.png";
import inst from "../../images/instagram.png";
import like from "../../images/likeb.png";

import Logo from "../logo/logo";
import Buttonlinksmall from "../buttonlinksmall/buttonlinksmall";
import Linksmall from "../linksmall/linksmallsmall";

export function Footer() {
  const onClick = () => {};
  return (
    <div className={styles.main}>
      <div className={styles.left}>
        <Logo></Logo>
      </div>
      <div className={styles.right}>
        <Buttonlinksmall link="/cart" image={inst}></Buttonlinksmall>
        <Buttonlinksmall link="/cart" image={tel}></Buttonlinksmall>
      </div>
    </div>
  );
}
export default Footer;
