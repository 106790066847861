import Cards from "../../components/cards/cards";
import OneHeader from "../../components/oneheader/oneheader";
import styles from "./onepage.module.css";

function OnePage() {
  return (
    <div className={styles.main}>
      <OneHeader></OneHeader>
      <Cards></Cards>
    </div>
  );
}

export default OnePage;
