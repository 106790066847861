import styles from "./person.module.css";
import imagephone from "../../images/phone.png";
import imageenvelope from "../../images/envelope.png";
import Button from "../button/button";
import Buttonlink from "../buttonlink/buttonlink";
import { useResize } from "../../utils/hook";
import Buttonlinksmall from "../buttonlinksmall/buttonlinksmall";
import Buttonsmall from "../buttonsmall/buttonsmall";

type TOne = {
  email: string;
  phone: string;
  name: string;
  rol: string;
  description: string;
  avatar: string;
};

const Person = (props: TOne) => {
  const one: TOne = {
    email: props.email,
    phone: props.phone,
    name: props.name,
    rol: props.rol,
    description: props.description,
    avatar: props.avatar,
  };
  const onClick = () => {};
  const wl = useResize().isScreenMd;

  return (
    <div className={styles.main}>
      <div className={styles.center}>
        <img className={styles.image} src={one.avatar} alt={one.avatar} />
        <div className={styles.name}>
          <h1 className={styles.title}>{one.name}</h1>
          <div className={styles.rol}>{one.rol}</div>
        </div>
        {!wl && (
          <div className={styles.top}>
            <Buttonsmall func={onClick()}></Buttonsmall>
          </div>
        )}
      </div>

      <div className={styles.right}>
        {wl && <Button name={"Выход"} func={onClick()}></Button>}
      </div>

      <div></div>
      <section className={styles.desc}>
        <div className={styles.description}>{one.description}</div>
        <div className={styles.contact}>
          <div className={styles.contactline}>
            <img className={styles.icon} src={imagephone} alt="phone" />
            <div>{one.phone}</div>
          </div>
          <div className={styles.contactline}>
            <img className={styles.icon} src={imageenvelope} alt="mail" />
            <div>{one.email}</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Person;
