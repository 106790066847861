import { NavLink } from "react-router-dom";
import FormReg from "../../components/regform/regform";
import styles from "./main.module.css";
import Banner from "../../components/banner/banner";
import imageone from "../../images/rr.png";
import imagetwo from "../../images/rr2.png";
import Cards from "../../components/cards/cards";

function MainPage() {
  return (
    <div className={styles.main}>
      <div className={styles.banner}>
        <Banner
          image={[imageone, imagetwo]}
          textone="Up To 30% Off"
          texttwo="Don`t leave your summer outfits to chance-stock up on your holiday
          closet now"
        ></Banner>
      </div>
      <div>
        <Cards></Cards>
      </div>
    </div>
  );
}

export default MainPage;
