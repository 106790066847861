import { NavLink } from "react-router-dom";
import styles from "./logo.module.css";
import icon from "../../images/logocross.png";

export function Logo() {
  return (
    <NavLink className={styles.title} to="/">
      <svg className={styles.circle} viewBox="0 0 100 100">
        <path id="circle" d="M 0,50 a 50,50 0 1,1 0,1 z" />
        <text>
          <textPath xlinkHref="#circle">{`CROSS CRAFT ✮ CROSS CRAFT ✮`}</textPath>
        </text>
      </svg>

      <img className={styles.icon} src={icon} alt="logo" />
    </NavLink>
  );
}
export default Logo;
