import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getbrends, getproducts } from "../utils/api";

export type Tbrend = {
  id: string;
  name: string;
  img: string;
};

export type Tbrends = {
  brends: Tbrend[];
  isLoad: boolean;
};

export const initialState: Tbrends = {
  brends: [],
  isLoad: false,
};

export const fetchList = createAsyncThunk<
  Tbrend[],
  undefined,
  { rejectValue: string }
>("brends/get", async () => {
  const res = await getbrends();
  const data = res.data;
  return data;
});

export const brends = createSlice({
  name: "brends",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchList.pending, (state) => {
        state.isLoad = false;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        console.log(action.payload);
        state.brends = action.payload;
        state.isLoad = true;
      })
      .addCase(fetchList.rejected, (state) => {
        state.isLoad = false;
      });
  },
});

export default brends.reducer;
