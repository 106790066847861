import { NavLink } from "react-router-dom";
import { useResize } from "../../utils/hook";
import Button from "../button/button";
import Buttonsmall from "../buttonsmall/buttonsmall";
import styles from "./header.module.css";
import cart from "../../images/cartt.png";
import phone from "../../images/phone.png";
import tel from "../../images/telegram.png";
import inst from "../../images/instagram.png";
import like from "../../images/likeb.png";

import Logo from "../logo/logo";
import Buttonlinksmall from "../buttonlinksmall/buttonlinksmall";
import Linksmall from "../linksmall/linksmallsmall";

export function Header() {
  const wl = useResize().isScreenMd;
  const onClick = () => {};
  return (
    <div className={styles.main}>
      <div className={styles.left}>
        <Logo></Logo>
        <div className={styles.title}>Taste is in the small details</div>
      </div>
      <div className={styles.right}>
        <Buttonlinksmall link="/like" image={like}></Buttonlinksmall>
        <Buttonlinksmall link="/cart" image={inst}></Buttonlinksmall>
        <Buttonlinksmall link="/cart" image={tel}></Buttonlinksmall>
        <Linksmall link="/cart" image={cart}></Linksmall>
      </div>
    </div>
  );
}
export default Header;
