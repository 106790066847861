import { NavLink } from "react-router-dom";
import styles from "./buttonlinksmall.module.css";
import strela from "../../images/strela.png";

type TButton = {
  link: string;
  image: string;
};

function Buttonlinksmall(props: TButton) {
  return (
    <NavLink className={styles.main} to={props.link}>
      <img className={styles.icon} src={props.image} alt="exit" />
    </NavLink>
  );
}

export default Buttonlinksmall;
