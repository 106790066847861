import styles from "./button.module.css";

type TButton = {
  name: string;
  func: void;
};
function Button(props: TButton) {
  return (
    <button className={styles.main} onClick={() => props.func}>
      {props.name}
    </button>
  );
}

export default Button;
